.top-nav {
  display: flex;
  font-family: "Antic";
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  background-color: #121212;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  .top-nav-link {
    a {
      font-size: 12px;
      line-height: 1.5;
      color: #c4c4c4;
      padding: 6px 20px;
      transition: 0.3s;

      svg {
        width: 16px;
        margin-right: 5px;
      }

      &:hover {
        color: #f7f7f7;
      }
    }
  }

  .end {
    flex: 1;
    text-align: right;
  }
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;

  .logo-container {
    position: sticky;
    background: #121212;
    display: flex;
    justify-content: center;
    padding: 8px 0;

    .logo {
      height: 50px;
      transition: 0.3s;

      @media screen and (max-width: 670px) {
        height: 40px;
      }

      &.narrow {
        height: 25px;
      }
    }
  }

  .search-button {
    background-color: #f8f6f5;
    border-radius: 0;
    border-right: 1px solid #121212;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 20px;
  }

  .navbar-link {
    font-family: "MvEamaanXP";
    flex: auto;
    padding: 0 20px;
    text-align: center;
    border-right: 1px solid #121212;
    border-bottom: 1px solid #121212;
  }

  .menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid #121212;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .menu-button-white {
    color: white;
    position: absolute;
    right: 0px;
    top: 12px;
    display: none;
  }

  .navbar-links {
    background-color: #f8f6f5;
    direction: rtl;
    display: flex;
    flex-wrap: wrap;
    // border-bottom: 1px solid #121212;
    transition: all 0.3s;
  }

  .navbar-dropdown > :first-child {
    border-right: none;
  }

  .mobile-nav {
    display: none;
  }

  @media screen and (max-width: 700px) {
    .menu-button-black {
      display: none;
    }

    .menu-button-white {
      display: block;
    }

    .desktop-nav {
      display: none;
    }

    .mobile-nav {
      display: flex;
    }
  }
}
