/*--- Custom CSS ---*/

.video-details {
  padding-right: 0px;
}

.player {
  padding-right: 0 !important;
}

.ad img {
  width: 100%;
}

/* .ad.fw-horizontal img {
  padding-top: 20px;
  padding-bottom: 20px;
} */

.row.card-body {
  padding: 0 !important;
}

.card-columns .card-body {
  box-shadow: 0px 1px 2px 0px rgba(128, 128, 128, 0.15);
  border: none;
  border-radius: 0;
}

/*.second-set .card-body and .video-feed .card-body removed to remove shadow from featured sections*/

.second-set .card.news-card {
  box-shadow: 0px 2px 2px 0px rgba(128, 128, 128, 0.2);
  border: none;
  border-radius: 0;
}
.card.news-card.side {
  height: 8%;
}

.card.news-card.side .row {
  height: 100%;
}

.ms-img {
  padding: 20px;
  height: 100%;
  width: 100%;
}

.video-details .card-body {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding-right: 0;
  color: #828282;
}

.video-details .card-body h2 {
  font-size: 33px;
  color: #6a6a6a;
}

.video-details .card-body h5 {
  font-family: "MV Typewriter Regular";
  font-size: 18px;
  color: #726f6f;
}

.blog-posts {
  display: flex;
  flex-wrap: wrap;
}

.live-cards .card {
  box-shadow: 0px 1px 3px 0px rgba(128, 128, 128, 0.9);
}

/* hr {
  background-color: #000000;
  margin-bottom: 40px;
} */

hr.comment-divider {
  border-top: 2px solid #c4c4c4;
  margin-right: 40px;
  margin-left: 40px;
}

hr.article-divider {
  border-bottom: 1px solid #fff;
  margin: 0 20px 0 20px;
  z-index: 99px;
}

/* .card-body {thing
  box-shadow: 0px 2px 2px 0px rgba(128, 128, 128, 0.1);
} */

.no-shadow {
  box-shadow: none;
}

.no-hover-shadow:hover {
  box-shadow: none !important;
}

.set-shadow-normal {
  box-shadow: 0px 3px 2px 0px rgba(165, 165, 165, 0.2);
}

.set-shadow-light {
  box-shadow: 0px 2px 2px 0px rgba(202, 202, 202, 0.2);
}

.card {
  box-shadow: 0px 2px 2px 0px rgba(128, 128, 128, 0.2);
  transition: box-shadow 0.5s;
  border-radius: 0px;
  border: none;
}

.card:hover {
  box-shadow: 0px 2px 8px 0px rgba(128, 128, 128, 0.6);
  transition: box-shadow 0.5s;
}

.card-title {
  margin-bottom: 0;
}

.featured-story .card-body {
  padding: 20px 0;
}

.category-page.card-body {
  padding: 30px;
}

.card-body.masonry {
  padding: 20px 20px 20px 20px !important;
}

.card-text.card-tag {
  margin-bottom: 10px;
  color: #828282;
}

.masonry-card-top .card-text.card-tag {
  margin-bottom: 0px;
}

.see-more .card-text.card-tag {
  margin-bottom: 0px;
}

.category-page .card-text.card-tag {
  margin-bottom: 0px;
}

.card-title.subject {
  font-family: "MV Typewriter Regular" !important;
  font-size: 23px;
}

.featured-story .card-title.subject {
  font-size: 23px;
}

.card-title.abstract {
  font-family: "MV Typewriter Regular" !important;
  font-size: 18px;
}

.row.card-masonry {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 50px;
}

.featured-category-title {
  background-color: #252525;
  color: #ffffff;
}

.featured-category img {
  padding: 0;
}

.see-more .card-text {
  font-size: 18px;
  margin-bottom: 0;
}

.see-more {
  background-color: #c4c4c4;
  margin-top: -5px;
  color: #555555;
}

.see-more svg {
  stroke: #555555;
}

.see-more:hover {
  background-color: #252525;
  color: #ffffff !important;
}

.see-more:hover svg {
  stroke: #ffffff;
}

.see-more .card-body {
  padding: 6px !important;
}

.news-card .card-img-top {
  padding: 20px 20px 0px 20px;
}

.featured-story .three-col.card-img-top {
  padding: 20px 20px 0px 20px;
}

.news-card .card-tag {
  display: inline;
}

.masonry-ad img {
  padding: 10px 0px 0px 0px !important;
}

.video-feed,
.twitter-container {
  background-color: #d5d3d2;
  padding-bottom: 50px;
}

.tiktok-feed {
  background-color: #fff;
  padding-bottom: 100px;
}

.tiktok-feed h2 {
  color: #333;
}

.tiktok-feed img {
  width: 100%;
}

.tiktok-col {
  padding-left: 0;
  padding-right: 0;
}

.video-feed .featured-category {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #fff;
}

.player {
  position: relative;
  width: 100%;
  height: 0;
  /* padding-bottom: 46.25%; */
}

.ytvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.second-set.featured-category {
  margin-top: 25px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* .featured-news-two {
  padding-bottom: 50px;
} */

.card-text.lora {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18;
}

.comment-submit li {
  /* padding: 10px 20px; */
  border: 2px solid #000000;
  background-color: #ffffff00;
  color: #000000;
  transition: background-color 0.5s, color 0.5s;
}

.comment-submit li:hover {
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.5s, color 0.5s;
}

.comment,
.comments {
  background-color: rgba(255, 255, 255, 0.438);
}

.video-player .vp-container {
  background-image: linear-gradient(
      to bottom,
      rgb(0, 0, 0),
      rgba(60, 60, 60, 0.5)
    ),
    url("../assets/images/video-thumb.png");
  background-size: cover;
  background-position: center center;
}

.vim {
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
}

.video-cat-tags ul li {
  display: inline-flex;
  list-style: none;
  margin-bottom: 0;
}

.lb-tag {
  color: #ed4545;
}

/* Controls the size of embeded tweets */
.live-cards .twitter-tweet {
  max-width: 400px !important;
}

.twitter-tweet {
  max-width: 100% !important;
}

.comment-section {
  background-color: #f1edeb;
}

.comment-section-container {
  margin-right: 260px;
  margin-left: 260px;
}

.cover-img {
  background-size: cover;
  height: 80px;
}

.asx {
  display: none;
}

.livefeed .card {
  height: 115px;
}

.livefeed .row,
.livefeed .card-body {
  height: 100%;
}

.article-padding-fix .no-l {
  padding-left: 15px;
}

.img-container {
  background: url("../assets/images/default.png");
  background-size: cover;
  background-position: center center;
  padding-right: 0px;
  padding-left: 0px;
  min-height: 150px;
}

.featured-articles .img-container {
  min-height: 120px;
}

.featured-story .img-container {
  min-height: 500px;
}

.small-card .img-container {
  min-height: 300px;
}

.recent .img-container {
  min-height: 100px;
}

.liveblog .img-container {
  min-height: 100px;
}

.img-padding {
  padding: 20px;
}

.card-padding {
  padding: 20px;
}

.card-padding-side {
  padding: 20px 0 20px 20px;
}

.margin-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.article-page-container img {
  width: 100% !important;
  height: 100% !important;
}

.statement .img-container {
  min-height: 650px;
}
.image-top .img-container {
  min-height: 400px;
}
.livefeed .card {
  overflow: hidden;
}

.card-img-top {
  border-radius: 0px;
}

/* Rows */

.row-header {
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.card .row {
  padding-top: 0;
}

/* Footer */

.footer {
  background-color: #012d46;
  /* margin-top: 100px; */
  padding: 80px clamp(20px, 10vw, 80px);
}

.footer .navbar-brand,
.footer .row {
  padding: 0px;
}

.footer .about-us {
  padding: 25px 0 0 0;
  color: #726f6f;
  font-family: "Lora", serif;
  font-size: 14px;
  line-height: 2;
}

.footer ul {
  padding-top: 25px;
}

.footer a {
  font-family: "Antic", sans-serif !important;
  font-size: 16px;
  text-transform: uppercase;
  color: #726f6f;
  padding-top: 5px;
}

.first {
  padding-top: 0px !important;
}

.footer a {
  color: #726f6f;
}

.footer a:hover {
  color: #868686;
  text-decoration: none;
}

.social-media img {
  padding-top: 40px;
  padding-right: 20px;
}

.social-media-bar img {
  padding-right: 20px;
}

.footer-menu {
  padding-right: 0;
  padding-left: 0;
}

.card-columns {
  column-count: 2 !important;
}
/*--- Media Queries ---*/

/*--- Mobile and lower ---*/
@media only screen and (max-width: 768px) {
  .card-columns {
    column-count: 1 !important;
  }

  .row {
    padding: 20px 0px 0px 0px;
  }

  .no-tbp {
    padding: 0px 0px 0px 0px;
  }

  hr {
    margin-bottom: 10px;
  }

  .mtop-50 {
    margin-top: 10px;
  }

  .top-40 {
    padding-top: 10px;
  }

  .tb-40 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .bottom-20 {
    padding-bottom: 10px;
  }

  .no-tbp-mobile {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobile-tb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-top-10 {
    padding-top: 10px;
  }

  .mobile-top-20 {
    padding-top: 20px;
  }

  .mobile-top-30 {
    padding-top: 30px;
  }

  .mobile-bottom-20 {
    padding-bottom: 20px;
  }

  .mobile-top-rm {
    padding-top: 0;
  }

  .mobile-lr-rm {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-tb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-l-20 {
    padding-left: 20px !important;
  }

  .row.card-masonry {
    padding-bottom: 0px;
  }

  .dhivehi {
    font-size: 15px;
  }

  .card-title.subject {
    font-size: 16px;
    transition: font-size 1s;
  }

  .card-title.abstract {
    font-size: 15px;
    transition: font-size 1s;
  }

  .nav-pills .nav-link {
    font-size: 12px !important;
  }
  .featured-story .card-text.dhivehi {
    font-size: 16px !important;
  }

  .featured-story .card-title.dhivehi {
    font-size: 16px !important;
  }

  .featured-category-title .card-title.dhivehi {
    font-size: 25px !important;
  }

  .page-container {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .nav-pills .nav-link {
    padding: 8px 0px;
  }

  .first-nav {
    padding: 0 !important;
  }

  .font-18 {
    font-size: 15px;
  }

  .font-24 {
    font-size: 18px;
  }

  .font-28 {
    font-size: 20px;
  }

  .font-30 {
    font-size: 20px;
  }

  .font-32 {
    font-size: 23px;
  }

  .font-34 {
    font-size: 24px;
  }

  .font-43 {
    font-size: 25px;
  }

  .lh-45 {
    line-height: 30px;
  }

  .lh-55 {
    line-height: 30px;
  }

  .lh-75 {
    line-height: 35px;
  }

  .lh-1 {
    line-height: 1;
  }

  .live-feed-container,
  .article-page-container {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .live-feed-inner-container,
  .article-page-inner-container {
    padding-right: 5px;
  }

  .comment-section-container {
    margin-right: 15px;
    margin-left: 15px;
  }

  .comment-section-container.mtop-50 {
    margin-top: 0px;
  }

  .featured-articles,
  .relevant-articles {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .no-mob-sides {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .statement .img-container {
    min-height: 480px;
  }

  .image-top .img-container {
    min-height: 400px;
  }

  .embed.ad img {
    padding-bottom: 40px;
  }

  .comment-submit li {
    padding: 5px 5px;
    border: 1px solid #000000;
    transition: background-color 0.5s, color 0.5s;
  }

  .comment-submit .font-23 {
    font-size: 10px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 0px;
    padding-left: 0px;
  }

  .row.justify-content-center.comment-section {
    padding-right: 10px;
    padding-left: 10px;
  }

  .mobile-lr-10 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .mobile-lr-15 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .mobile-lr-20 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .right-10 {
    padding-right: 10px;
  }

  .right-20 {
    padding-right: 20px;
  }

  .left-20 {
    padding-left: 20px;
  }

  .featured-story .img-container {
    min-height: 150px;
  }

  .liveblog .img-container {
    min-height: 90px;
  }

  .featured-articles .img-container {
    min-height: 90px;
  }

  .twitter-container h2 {
    font-size: 33px;
  }
}

/*--- Ipad and higher ---*/
@media only screen and (min-width: 992px) {
}

/*--- Ipad and lower ---*/
@media only screen and (max-width: 991px) {
  .image-top .img-container,
  .image-side .img-container {
    min-height: 100px;
  }
}

/*--- 13" and lower ---*/
@media only screen and (min-width: 769px) and (max-width: 1675px) {
  .featured-story .img-container {
    min-height: 300px;
  }

  .recent .img-container {
    min-height: 170px;
  }

  .card-title.subject {
    font-size: 18px;
    transition: font-size 1s;
  }

  .card-title.abstract {
    font-size: 16px;
    transition: font-size 1s;
  }

  .card-text.card-tag {
    margin-bottom: 0px !important;
    transition: margin-bottom 1s;
  }

  .livefeed .col-9 {
    padding-right: 0px;
  }

  .font-18 {
    font-size: 16px;
  }

  .font-24 {
    font-size: 20px;
  }

  .font-28 {
    font-size: 25px;
  }

  .font-30 {
    font-size: 26px;
  }

  .font-32 {
    font-size: 27px;
  }

  .font-34 {
    font-size: 28px;
  }

  .font-43 {
    font-size: 30px;
  }

  .lh-55 {
    line-height: 40px;
  }

  .lh-75 {
    line-height: 55px;
  }

  .left-100 {
    padding-left: 100px;
  }

  .live-feed-container,
  .article-page-container {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .live-feed-inner-container,
  .article-page-inner-container {
    padding-right: 40px;
  }

  .article-page-inner-container.english {
    padding-right: 0;
    padding-left: 40px;
  }

  .comment-section-container {
    margin-right: 140px;
    margin-left: 140px;
  }
}

/*--- 24" and higher ---*/
@media only screen and (min-width: 1940px) {
  .page-container {
    padding-right: 300px !important;
    padding-left: 300px !important;
  }
}

.desktop-container {
  background-color: red;
}

.footer li {
  padding-top: 15px;
}

.footer ul {
  padding-bottom: 20px;
}

.test.card img {
  padding: 20px;
}

.social-left-20 {
  padding-left: 20px !important;
}

.feetha h2 {
  padding-bottom: 20px;
}

.video-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;
}
.video-embed-container iframe,
.video-embed-container object,
.video-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.see-more:hover {
  background-color: #252525;
  color: #fff !important;
  transition: background-color 1s;
}

.article-page-container {
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
}

.hide-on-mobile {
  @media only screen and (max-width: 770px) {
    display: none;
  }
}

.hide-on-desktop {
  @media only screen and (min-width: 770px) {
    display: none;
  }
}

.reply-button {
  border: 2px solid #000000;
  background-color: #ffffff00;
  color: #000000;
  transition: background-color 0.5s, color 0.5s;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    border: 1px solid #000000;
    font-size: 10px;
  }
}

.reply-button:hover {
  background-color: #000000;
  color: #ffffff;
}
