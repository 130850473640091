body {
  padding: 0;
  margin: 0;
  background: #f8f6f5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: 35px;
  font-family: "Fira Sans", sans-serif;
}

.page-container {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.live-feed-container,
.article-page-container {
  padding-right: 100px !important;
  padding-left: 100px;
}

.live-feed-inner-container,
.article-page-inner-container {
  padding-right: 100px;
}

.row {
  padding: 25px 0px 0px 0px;
}

.row.page-container {
  margin-right: 0px;
  margin-left: 0px;
}

.page-container .row {
  margin-right: 0px;
  margin-left: 0px;
}

.card-columns .card {
  margin-top: 10px;
}

h2 {
  font-size: 33px;
}

a,
a:hover {
  text-decoration: none;
}

a:link,
a:visited,
a:active {
  color: #000000;
}

a:hover {
  color: #868686;
}

.nhc a:hover {
  color: #000000;
}
