@font-face {
  font-family: "Faruma";
  src: url("../assets/fonts/Faruma.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MV Typewriter Bold";
  src: url("../assets/fonts/Typewriter-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MV Typewriter Regular";
  src: url("../assets/fonts/Typewriter-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MvEamaanXP";
  src: url("../assets/fonts/MvEamaanXP.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fira Sans";
  src: url("../assets/fonts/FiraSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Antic";
  src: url("../assets/fonts/Antic-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lora";
  src: url("../assets/fonts/Lora-VariableFont_wght.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM Serif Display";
  src: url("../assets/fonts/DMSerifDisplay-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

.dhivehi h1,
.dhivehi h2,
.dhivehi h3,
.dhivehi h4,
.dhivehi h5 {
  line-height: 1.6;
}

.dhivehi {
  text-align: right;
  font-family: "MvEamaanXP" !important;
  direction: rtl;
  letter-spacing: 0.5px;
}

.center-force {
  text-align: center !important;
}

.dhivehi .card-title {
  line-height: 2;
}

.featured-story .card-title.dhivehi,
.featured-story .card-text.dhivehi {
  font-family: "MV Typewriter Regular" !important;
}

@media screen and (max-width: 760px) {
  .featured-story .card-title.dhivehi,
  .featured-story .card-text.dhivehi,
  .dhivehi .card-title.subject {
    font-family: "MV Typewriter Bold" !important;
  }
}

.card-text.dhivehi {
  font-size: 18px !important;
}

.card-text.dhivehi.abstract {
  font-size: 23px !important;
}

.live-cards .card-title h2 {
  font-size: 26px;
}

.live-cards .card-title h3 {
  font-size: 22px;
}

.live-cards .card-title h4 {
  font-size: 20px;
}

.typewriter {
  font-family: "MV Typewriter Regular" !important;
}

.typewriter-bold {
  font-family: "MV Typewriter Bold" !important;
}

.mveamaan {
  font-family: "MvEamaanXP";
}

.antic {
  font-family: "Antic", sans-serif !important;
}

.fsans {
  font-family: "Fira Sans", sans-serif !important;
  line-height: 1.5;
}

.lora {
  font-family: "Lora", serif !important;
}

.ad .antic {
  font-size: 10px;
  letter-spacing: 0.05em;
  color: #6a6a6a;
  text-align: left;
  margin-bottom: 0px;
}

.ad {
  margin-top: -15px;
  line-height: 15px;
}

.f-grey {
  color: #828282 !important;
}

.f-lgrey {
  color: #c4c4c4 !important;
}

.f-black {
  color: #000000 !important;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}

.font-23 {
  font-size: 23px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-34 {
  font-size: 34px;
}

.font-43 {
  font-size: 43px;
}

.heading {
  letter-spacing: 0.055em;
}

.card-title.dhivehi.heading {
  letter-spacing: 0.15em;
}

.lh-45 {
  line-height: 45px;
}

.lh-55 {
  line-height: 55px;
}

.lh-75 {
  line-height: 75px;
}

.ls-1 {
  letter-spacing: 0.1em;
}

.ls-2 {
  letter-spacing: 0.21em;
}

.hr-width-6 hr {
  border-top: 6px solid;
}

.caps {
  text-transform: uppercase !important;
}

.fw-600 {
  font-weight: 600;
}

.blockquote {
  font-family: "MV Typewriter Bold";
  font-size: 27px;
  line-height: 2;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 60px;

  &::before {
    content: url("../assets/images/quote.svg");
    display: block;
    position: absolute;
    transform: translateX(60px);
  }

  &::after {
    content: "";
    display: block;
    width: 25%;
    height: 20px;
    border-bottom: 5px solid black;
  }
}

.ordered-list {
  list-style: none;
  counter-reset: list-counter;

  li {
    counter-increment: list-counter;

    &::before {
      content: counter(list-counter) " ";
      // padding: 10px;
      font-weight: bold;
      position: absolute;
      transform: translate(20px, 2px);
    }
  }
}

.unordered-list {
  list-style: none;
  li::before {
    content: url("../assets/images/circle-fill.svg");
    display: block;
    position: absolute;
    transform: translate(17px, 1px);
  }
}

.article-body {
  .twitter-tweet {
    flex-direction: column;
  }
}

.article-body-english {
  .blockquote {
    font-family: "Fira Sans" !important;
    font-weight: 600 !important;
    font-size: 27px;
    line-height: 2;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 60px;

    &::before {
      transform: translateX(-50px) scaleX(-1);
    }
  }

  .ordered-list {
    li::before {
      transform: translateX(-20px);
    }
  }

  .unordered-list {
    li::before {
      transform: translate(-17px, 0px);
    }
  }
}
