.mtop-30 {
  margin-top: 30px;
}

.mtop-50 {
  margin-top: 50px;
}

.mtop-50 {
  margin-top: 50px;
}

.rm-mbottom-100 {
  margin-bottom: -100px;
}

.top-10 {
  padding-top: 10px;
}

.top-20 {
  padding-top: 20px;
}

.top-30 {
  padding-top: 30px;
}

.top-40 {
  padding-top: 40px;
}

.top-50 {
  padding-top: 50px;
}

.bottom-10 {
  padding-bottom: 10px;
}

.bottom-20 {
  padding-bottom: 20px;
}

.bottom-30 {
  padding-bottom: 30px;
}

.bottom-40 {
  padding-bottom: 40px;
}

.bottom-50 {
  padding-bottom: 50px;
}

.bottom-100 {
  padding-bottom: 100px;
}

.left-10 {
  padding-left: 10px;
}

.left-15 {
  padding-left: 15px;
}

.left-20 {
  padding-left: 20px;
}

.mveamaan {
  padding-left: 20px;
}

.left-30 {
  padding-left: 30px;
}

.left-200 {
  padding-left: 200px;
}

.right-100 {
  padding-right: 100px;
}
.right-200 {
  padding-right: 200px;
}

.right-10 {
  padding-right: 10px;
}

.right-15 {
  padding-right: 15px;
}

.right-20 {
  padding-right: 20px;
}

.right-30 {
  padding-right: 30px;
}

.right-60 {
  padding-right: 60px;
}

.lrp-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.lrp-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.no-padding {
  padding: 0;
}

.no-sides {
  padding-right: 0;
  padding-left: 0;
}

.no-l {
  padding-left: 0;
}

.no-r {
  padding-right: 0;
}

.no-tbp {
  padding-top: 0;
  padding-bottom: 0;
}

.no-b {
  padding-bottom: 0;
}

.no-t {
  padding-top: 0;
}
.no-tb-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.no-b-margin {
  margin-bottom: 0;
}

.no-side-margin {
  margin-right: 0;
  margin-left: 0;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}
.gap-30 {
  gap: 30px;
}
